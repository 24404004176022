import React from 'react';
import paglogo from '../img/pag_logo.png';


export default function LogoPage ({onClickLogin}) {


  return(
    <div className="logo-login-page">
       <img src={paglogo} alt="Grad Pag grb" title="Službena stranica grada Paga"/>
        <h3>GRAD PAG</h3>
        <p>Evidencija komunalne infrastrukture</p>
        <button className="login-button" onClick={onClickLogin}>Prijava u aplikaciju</button>
    </div>
  )
};
