import React, { useEffect } from 'react';
import MapLeaflet from './Komponente/MapLeaflet';
import Login from './Komponente/Login';
import useToken from './Komponente/useToken';

import './App.css';

export default function App() {
  const { token, setToken } = useToken();
  
  useEffect(() => {
    document.title = "EKI Grad Pag"
  }, [])
  
  if(!token) {
    return <Login setToken={setToken} /> //conditional statement to display Login if the token is falsy.
  };

  const handleLogOutResetToken = (event) => {
    sessionStorage.removeItem(token);
    setToken('');
  };


  return (
      <div>
        <MapLeaflet handleLogOut={handleLogOutResetToken}/> 
      </div>
    )
}