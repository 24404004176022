import React from "react";

export default function FormaPageNumber({value, onChange, maxpage, onDisabled}) {   
    return (
        <form className="forma-datatable">
            <label>
                Stranica
                <input type="text" value={value} onChange={onChange} disabled={onDisabled}/>
                od {maxpage}
            </label>
        </form>
    );
};