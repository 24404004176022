import React, { Component } from 'react';


export default class Checkbox extends Component{
    
   render() {
    return (
        <form className="checkbox-div">
            <label>
                <input type="checkbox" name={this.props.name} checked={this.props.checked} onChange={this.props.Change}/>
                <span style = {this.props.style}>{this.props.child}</span>
                <span>{this.props.nameCheckbox}</span>
            </label>
        </form>
    )
}
}