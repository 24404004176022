import React from 'react';
import {Modal, Button} from 'react-bootstrap';


export default function Information({handleClose, show}) {

    
    return (
        <Modal className="modal-information" show={show} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>EKI GIS aplikacija</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>WEB GIS pregednik kreiran je u svrhu lakšeg pregledavanja i pretraživanja, kvalitetnije vizualizacije, dohvaćanja dostupnih informacija te uspostave jedinstvene Evidencije komunalne infrastrukture na području Grada Paga.</p>
                <p>Za potrebe izrade aplikacije korišteni su službeni podaci Državne geodetske uprave i podaci Hrvatskih cesta: Digitalni ortofoto 2020 - WMS, neslužbene kopije zemljišno-knjižnih uložaka, podaci o katastarskim česticama i općinama, podaci o javnim cestama (Podaci su informativnog karaktera i ne mogu služiti kao javna isprava).</p>
                <p>powered by: <a rel="noreferrer" href="https://www.li-st.net/" target="_blank">li:st geoinformatika</a></p>

            </Modal.Body>
            <Modal.Footer>
                <Button className="buton-datatable" variant="primariy" >
                    <a className="dowbload-upute" download href={"/Upute za registrirane korisnike_v01.pdf"}>Preuzmi korisničke upute</a>
                </Button>
                <Button className="buton-datatable grey" variant="secondary" onClick={handleClose}>
                    Zatvori
                </Button>
            </Modal.Footer>
        </Modal>
    )
}