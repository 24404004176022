import React, { Component } from 'react';
import { BsReverseLayoutTextWindowReverse } from 'react-icons/bs';
import ModalTableKI from './ModalTableKI';



export default class OpenTableKI extends Component {
    state = {
        show: false,
    };

    handleClickClose = () => {
        this.setState({show:!this.state.show});
        if (!this.state.show) {
            this.props.closeSidebar('zatvori');
        } else  this.props.closeSidebar();
    };

    handleZoomOnMap = (a) => {
        this.props.onZoomOnMap(a);
    };

    sendJsonDataGeometry = (jsondata) => {
        this.props.handleJsonData(jsondata);
    };
    
    closeModal = (a) => {
        this.setState({show:a});
        this.props.resetLayer('reset');

    };

    render() {
        return(
            <div className="ikona_tablica">
                <button onClick = {this.handleClickClose} className="tablica_button"><BsReverseLayoutTextWindowReverse /></button>
                <ModalTableKI  zoomFeatureOnMap = {this.handleZoomOnMap} closeModal = {this.closeModal}  handleClose={this.handleClickClose} show={this.state.show} setJsonData={this.sendJsonDataGeometry}/>
            </div>
        )
    };
};