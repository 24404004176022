import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SidebarContent from './SidebarContent';
import Checkbox from './Checkbox';
import { faChevronRight, faChevronDown, faRoad, faCity} from '@fortawesome/free-solid-svg-icons';
import OpenTableCeste from './Tablica nerazvrstane ceste/OpenTableCeste';
import OpenTableKI from './Tablica komunalna infrastruktura/OpenTableKI';
import { MdSkipPrevious } from "react-icons/md";

import {FaDirections} from 'react-icons/fa';
import {AiOutlineMinus, AiOutlineDash} from "react-icons/ai";
import {BsFillSquareFill, BsFillCircleFill} from "react-icons/bs";

export default class  Sidebar extends Component{
    state = {
        activeBicikl: '',
        activeCollapse: '',
        activeitem: [],
        activeNaselje: '',
        cestePagInput: false,
        cesteMiskoviciInput: false,
        cesteGoricaInput: false,

        cesteBosanaInput: false,
        cesteDinjiskaInput: false,
        cesteKosljunInput: false,

        cesteSmokvicaInput: false,
        cesteStaraVasInput: false,
        cesteSimuniInput: false,
        cesteVlasiciInput: false,
        cesteVrciciInput: false,
        javneCesteDrzavneInput: false,
        javneCesteZupanijskeInput: false,
        javneCesteLokalneInput: false,
        rasvjeta1Input: false,
        rasvjeta2Input: false,
        rasvjeta3Input: false,
        rasvjeta4Input: false,
        rasvjeta5Input: false,
        rasvjeta6Input: false,
        rasvjeta7Input: false,
        grobljaInput: false,
        trgJavnePovrsineInput: false,
        plocniciJavnePovrsineInput: false,
        plazeJavnePovrsineInput: false,
        biciklisticke1835Input: false,
        biciklisticke1637Input: false,
        biciklisticke17953Input: false,
        biciklisticke2044Input: false,
        biciklistick3468Input: false,
        biciklistick5815Input: false,
        biciklistick7350Input: false,
        biciklistick9922Input: false,
        biciklisticke32792Input: false,
        biciklisticke33004Input: false,
        mostoviJavnePovrsineInput: false,
        pjesackeJavnePovrsineInput: false,
        parkiralistaNaplataJavnaInput: false,
        parkiralistaJavnaInput: false,
        sportskiTereniInput: false,
        djecjaIgralistaInput: false,
        parkoviInput: false,
        zeleniloInput: false,
        stajalistaPrijevozInput: false,
        spomeniciInput: false,
        odlagalistaInput: false,
        reciklaznaInput: false,
        trzniceInput: false,
        gradjevineInput: false,
        minimalize: false,
    }

    toggleChange = (evt) => {
 
        this.setState({ [evt.target.name]: evt.target.checked });

        let a = {
            checked: false
        };
          
        let checkboxProps = Object.create(a);
          
        checkboxProps.target = evt.target.name;
        checkboxProps.checked = evt.target.checked;
        this.props.OnMessageOut(checkboxProps);
      }
    
    handleExpandCollaps = (name) => {
        if (this.state.activeCollapse === name) {

          //If collapsiable is already visible and clicked on same then this will hide it
            this.setState({ activeCollapse: '' });
            this.setState({ activeitem: [] });
        } else {
            //To show collapsiable
            this.setState({ activeCollapse: name });
            this.setState({ activeitem: [] });
        }
        this.props.resetCesteLayer();
        this.props.resetKILayer();
    };

    handleClickOnBicik = (name) => {
        if (this.state.activeBicikl === name) {
            this.setState({ activeBicikl: '' });
        } else {
            this.setState({ activeBicikl: name });
        }
    }

    handleExpandSpan = (naselje) => {
          let newlist = [...this.state.activeitem];
          if (newlist.includes(naselje)) {
                newlist = newlist.filter(naziv => naziv !== naselje)
          } else {
                newlist.push(naselje);
            }
          this.setState({activeitem: newlist});

    };

    handleZoomOnMap = (prop) => {
        this.props.OnZoomOnMap(prop);
    };

    resetCesteLayer = (prop) => {
        this.props.resetCesteLayer(prop);
    }; 
    resetKILayer = (prop) => {
        this.props.resetKILayer(prop);
    }; 
    sendJsonData = (jsondata) => {
        this.props.createJsonData(jsondata);
    };

    closeSidebarOnclick = (close) => {
        if (close) {
            this.setState({minimalize: true});
        };
    };

    minimalizeSidebar = () => {
        this.setState({minimalize: !this.state.minimalize});
    };



   render() {

    const incompleteIcon =  <span className="span_icon"><FontAwesomeIcon icon={faChevronDown} /></span>;
    const completeIcon = <span className="span_icon"><FontAwesomeIcon icon={faChevronRight} /></span>;

    return (
        <div> 
            {this.props.showSidebar && 
            <div className={`sidebar-div ${this.state.minimalize ? 'left-sidebar': ''}` } id="sidebar-div">
                <div id="icon_slide" onClick={this.minimalizeSidebar}><MdSkipPrevious/></div>
                <div className={`sidebar-nav-menu ${this.state.minimalize ? 'nav-display': ''}` }>
                    <div className={`sidebar-nav-menu-item ${this.state.activeCollapse === "javneceste" ? 'item-active' : ''}`}  data-id="javneceste">
                        <div className="sidebar-nav-menu-item-head" >
                            <span className="span_icon" onClick={() => this.handleExpandCollaps("javneceste")}><FaDirections/></span>
                            <span className="sidebar-nav-menu-item-head-title" onClick={() => this.handleExpandCollaps("javneceste")}>Javne ceste</span>
                        </div>
                        <div className="sidebar-nav-menu-item-body">
                            <div>
                                <Checkbox
                                    child = {<AiOutlineMinus/>}
                                    style = {{
                                        color: "rgb(255, 2, 1)",
                                        backgroundColor: "white",
                                        marginRight: "5px",
                                        padding: "1px"
                                    }}
                                    nameCheckbox="Državne ceste"
                                    name="javneCesteDrzavneInput"
                                    checked={this.state.javneCesteDrzavneInput}
                                    Change={this.toggleChange.bind(this)}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    child = {<AiOutlineMinus/>}
                                    style = {{
                                        color: "rgb(0, 182, 0)",
                                        backgroundColor: "white",
                                        marginRight: "5px",
                                        padding: "1px"
                                    }}
                                    nameCheckbox="Županijske ceste"
                                    name="javneCesteZupanijskeInput"
                                    checked={this.state.javneCesteZupanijskeInput}
                                    Change={this.toggleChange.bind(this)}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    child = {<AiOutlineMinus/>}
                                    style = {{
                                        color: "rgb(248, 243, 11)",
                                        backgroundColor: "white",
                                        marginRight: "5px",
                                        padding: "1px"
                                    }}
                                    nameCheckbox="Lokalne ceste"
                                    name="javneCesteLokalneInput"
                                    checked={this.state.javneCesteLokalneInput}
                                    Change={this.toggleChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={`sidebar-nav-menu-item ${this.state.activeCollapse === "registar" ? 'item-active' : ''}`}  data-id="registar" >
                        <div className="sidebar-nav-menu-item-head">
                            <span className="span_icon"  onClick={() => this.handleExpandCollaps("registar")}><FontAwesomeIcon icon={faRoad}/></span>
                            <span className="sidebar-nav-menu-item-head-title" onClick={() => this.handleExpandCollaps("registar")}>Registar nerazvrstanih cesta</span>
                            {this.state.activeCollapse === "registar" ? <OpenTableCeste  resetLayer={this.resetCesteLayer} onZoomOnMap={this.handleZoomOnMap} handleJsonData={this.sendJsonData} closeSidebar={this.closeSidebarOnclick}/> : null}
                        </div>
                        <div className="sidebar-nav-menu-item-body">
                            <div>
                                <Checkbox
                                    child = {<AiOutlineMinus/>}
                                    style = {{
                                        color: "red",
                                        backgroundColor: "white",
                                        marginRight: "5px",
                                        padding: "1px"
                                    }}
                                    nameCheckbox="Naselje Bošana"
                                    name="cesteBosanaInput"
                                    checked={this.state.cesteBosanaInput}
                                    Change={this.toggleChange.bind(this)}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    child = {<AiOutlineMinus/>}
                                    style = {{
                                        color: "red",
                                        backgroundColor: "white",
                                        marginRight: "5px",
                                        padding: "1px"
                                    }}
                                    nameCheckbox="Naselje Dinjiška"
                                    name="cesteDinjiskaInput"
                                    checked={this.state.cesteDinjiskaInput}
                                    Change={this.toggleChange.bind(this)}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    child = {<AiOutlineMinus/>}
                                    style = {{
                                        color: "red",
                                        backgroundColor: "white",
                                        marginRight: "5px",
                                        padding: "1px"
                                    }}
                                    nameCheckbox="Naselje Gorica"
                                    name="cesteGoricaInput"
                                    checked={this.state.cesteGoricaInput}
                                    Change={this.toggleChange.bind(this)}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    child = {<AiOutlineMinus/>}
                                    style = {{
                                        color: "red",
                                        backgroundColor: "white",
                                        marginRight: "5px",
                                        padding: "1px"
                                    }}
                                    nameCheckbox="Naselje Košljun"
                                    name="cesteKosljunInput"
                                    checked={this.state.cesteKosljunInput}
                                    Change={this.toggleChange.bind(this)}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    child = {<AiOutlineMinus/>}
                                    style = {{
                                        color: "red",
                                        backgroundColor: "white",
                                        marginRight: "5px",
                                        padding: "1px"
                                    }}
                                    nameCheckbox="Naselje Miškovići"
                                    name="cesteMiskoviciInput"
                                    checked={this.state.cesteMiskoviciInput}
                                    Change={this.toggleChange.bind(this)}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    child = {<AiOutlineMinus/>}
                                    style = {{
                                        color: "red",
                                        backgroundColor: "white",
                                        marginRight: "5px",
                                        padding: "1px"
                                    }}
                                    nameCheckbox="Naselje Pag"
                                    name="cestePagInput"
                                    checked={this.state.cestePagInput}
                                    Change={this.toggleChange.bind(this)}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    child = {<AiOutlineMinus/>}
                                    style = {{
                                        color: "red",
                                        backgroundColor: "white",
                                        marginRight: "5px",
                                        padding: "1px"
                                    }}
                                    nameCheckbox="Naselje Smokvica"
                                    name="cesteSmokvicaInput"
                                    checked={this.state.cesteSmokvicaInput}
                                    Change={this.toggleChange.bind(this)}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    child = {<AiOutlineMinus/>}
                                    style = {{
                                        color: "red",
                                        backgroundColor: "white",
                                        marginRight: "5px",
                                        padding: "1px"
                                    }}
                                    nameCheckbox="Naselje Stara Vas"
                                    name="cesteStaraVasInput"
                                    checked={this.state.cesteStaraVasInput}
                                    Change={this.toggleChange.bind(this)}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    child = {<AiOutlineMinus/>}
                                    style = {{
                                        color: "red",
                                        backgroundColor: "white",
                                        marginRight: "5px",
                                        padding: "1px"
                                    }}
                                    nameCheckbox="Naselje Šimuni"
                                    name="cesteSimuniInput"
                                    checked={this.state.cesteSimuniInput}
                                    Change={this.toggleChange.bind(this)}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    child = {<AiOutlineMinus/>}
                                    style = {{
                                        color: "red",
                                        backgroundColor: "white",
                                        marginRight: "5px",
                                        padding: "1px"
                                    }}
                                    nameCheckbox="Naselje Vlašići"
                                    name="cesteVlasiciInput"
                                    checked={this.state.cesteVlasiciInput}
                                    Change={this.toggleChange.bind(this)}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    child = {<AiOutlineMinus/>}
                                    style = {{
                                        color: "red",
                                        backgroundColor: "white",
                                        marginRight: "5px",
                                        padding: "1px"
                                    }}
                                    nameCheckbox="Naselje Vrčići"
                                    name="cesteVrciciInput"
                                    checked={this.state.cesteVrciciInput}
                                    Change={this.toggleChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={`sidebar-nav-menu-item komunalna-infrastruktura ${this.state.activeCollapse === "komunalna" ? 'item-active' : ''}`}  data-id="komunalna">
                        <div className="sidebar-nav-menu-item-head" >
                            <span className="span_icon" onClick={() => this.handleExpandCollaps("komunalna")}><FontAwesomeIcon icon={faCity}/></span>
                            <span className="sidebar-nav-menu-item-head-title" onClick={() => this.handleExpandCollaps("komunalna")}>Komunalna infrastrukutra</span>
                            {this.state.activeCollapse === "komunalna" ? <OpenTableKI resetLayer={this.resetKILayer} onZoomOnMap={this.handleZoomOnMap} handleJsonData={this.sendJsonData} closeSidebar={this.closeSidebarOnclick}/> : null}
                        </div>
                        <div className="sidebar-nav-menu-item-body">
                            <SidebarContent
                                klasa = {this.state.activeitem.includes("javnePovrsine") ? 'item-display': ''}
                                handleExpand={()=>this.handleExpandSpan("javnePovrsine")}
                                ikonica = {this.state.activeitem.includes("javnePovrsine") ? incompleteIcon: completeIcon}
                                imeNaselja={'Javne površine bez prometa'}
                                body = {
                                    <div>
                                        <Checkbox
                                            child = {<BsFillSquareFill/>}
                                            style = {{
                                                color: "rgb(255,255,255)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            nameCheckbox = "Trg"
                                            name = "trgJavnePovrsineInput"
                                            checked = {this.state.trgJavnePovrsineInput}
                                            Change = {this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="Pločnici, prečaci i nogostupi"
                                            child = {<AiOutlineDash/>}
                                            style = {{
                                                color: "rgb(125,139,143)",
                                                marginRight: "5px",
                                                padding: "1px",
                                                backgroundColor: "white"
                                            }}
                                            name="plocniciJavnePovrsineInput"
                                            checked={this.state.plocniciJavnePovrsineInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="Plaže"
                                            child = {<BsFillSquareFill/>}
                                            style = {{
                                                color: "rgb(165,191,221)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="plazeJavnePovrsineInput"
                                            checked={this.state.plazeJavnePovrsineInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <div > 
                                            <span className="onhover-span" onClick={() => this.handleClickOnBicik("bicikl")}>
                                                <span >{this.state.activeBicikl.includes('bicikl') ? incompleteIcon : completeIcon}</span>
                                                <span className="bicikli-sidebar">Biciklističke i pješačke staze</span>
                                            </span>
                                            <div className={this.state.activeBicikl.includes('bicikl') ? 'showBicikl' : 'hideBicikl'}>
                                                <Checkbox
                                                    nameCheckbox="1 637 metara"
                                                    name="biciklisticke1637Input"
                                                    child = {<AiOutlineMinus/>}
                                                    style = {{
                                                        color: "yellow",
                                                        marginRight: "5px",
                                                        padding: "1px",
                                                        backgroundColor: "white"
                                                    }}
                                                    checked={this.state.biciklisticke1637Input}
                                                    Change={this.toggleChange.bind(this)}
                                                />
                                                <Checkbox
                                                    nameCheckbox="1 835 metara"
                                                    name="biciklisticke1835Input"
                                                    child = {<AiOutlineMinus/>}
                                                    style = {{
                                                        color: "rgb(254,201,141)",
                                                        marginRight: "5px",
                                                        padding: "1px",
                                                        backgroundColor: "white"
                                                    }}
                                                    checked={this.state.biciklisticke1835Input}
                                                    Change={this.toggleChange.bind(this)}
                                                />
                                                <Checkbox
                                                    nameCheckbox="2 044 metara"
                                                    name="biciklisticke2044Input"
                                                    child = {<AiOutlineMinus/>}
                                                    style = {{
                                                        color: "rgb(253,149,103)",
                                                        marginRight: "5px",
                                                        padding: "1px",
                                                        backgroundColor: "white"
                                                    }}
                                                    checked={this.state.biciklisticke2044Input}
                                                    Change={this.toggleChange.bind(this)}
                                                />
                                                <Checkbox
                                                    nameCheckbox="3 468 metara"
                                                    name="biciklistick3468Input"
                                                    child = {<AiOutlineMinus/>}
                                                    style = {{
                                                        color: "rgb(241,96,93)",
                                                        marginRight: "5px",
                                                        padding: "1px",
                                                        backgroundColor: "white"
                                                    }}
                                                    checked={this.state.biciklistick3468Input}
                                                    Change={this.toggleChange.bind(this)}
                                                />
                                                <Checkbox
                                                    nameCheckbox="5 815 metara"
                                                    name="biciklistick5815Input"
                                                    child = {<AiOutlineMinus/>}
                                                    style = {{
                                                        color: "rgb(205,63,113)",
                                                        marginRight: "5px",
                                                        padding: "1px",
                                                        backgroundColor: "white"
                                                    }}
                                                    checked={this.state.biciklistick5815Input}
                                                    Change={this.toggleChange.bind(this)}
                                                />
                                                <Checkbox
                                                    nameCheckbox="7 350 metara"
                                                    name="biciklistick7350Input"
                                                    child = {<AiOutlineMinus/>}
                                                    style = {{
                                                        color: "rgb(158,47,127)",
                                                        marginRight: "5px",
                                                        padding: "1px",
                                                        backgroundColor: "white"
                                                    }}
                                                    checked={this.state.biciklistick7350Input}
                                                    Change={this.toggleChange.bind(this)}
                                                />
                                                <Checkbox
                                                    nameCheckbox= "9 922 metara"
                                                    name="biciklistick9922Input"
                                                    child = {<AiOutlineMinus/>}
                                                    style = {{
                                                        color: "rgb(114,31,129)",
                                                        marginRight: "5px",
                                                        padding: "1px",
                                                        backgroundColor: "white"
                                                    }}
                                                    checked={this.state.biciklistick9922Input}
                                                    Change={this.toggleChange.bind(this)}
                                                />
                                                <Checkbox
                                                    nameCheckbox="17 953 metara"
                                                    name="biciklisticke17953Input"
                                                    child = {<AiOutlineMinus/>}
                                                    style = {{
                                                        color: "rgb(68,15,118)",
                                                        marginRight: "5px",
                                                        padding: "1px",
                                                        backgroundColor: "white"
                                                    }}
                                                    checked={this.state.biciklisticke17953Input}
                                                    Change={this.toggleChange.bind(this)}
                                                />
                                                <Checkbox
                                                    nameCheckbox="32 792 metara"
                                                    name="biciklisticke32792Input"
                                                    child = {<AiOutlineMinus/>}
                                                    style = {{
                                                        color: "rgb(24,15,62)",
                                                        marginRight: "5px",
                                                        padding: "1px",
                                                        backgroundColor: "white"
                                                    }}
                                                    checked={this.state.biciklisticke32792Input}
                                                    Change={this.toggleChange.bind(this)}
                                                />
                                                <Checkbox
                                                    nameCheckbox="33 004 metara"
                                                    name="biciklisticke33004Input"
                                                    child = {<AiOutlineMinus/>}
                                                    style = {{
                                                        color: "rgb(0,0,4)",
                                                        marginRight: "5px",
                                                        padding: "1px",
                                                        backgroundColor: "white"
                                                    }}
                                                    checked={this.state.biciklisticke33004Input}
                                                    Change={this.toggleChange.bind(this)}
                                                />
                                                
                                            </div>
                                        </div>
                                        <Checkbox
                                            nameCheckbox="Mostovi"
                                            child = {<BsFillSquareFill/>}
                                            style = {{
                                                color: "rgb(219,128,177)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="mostoviJavnePovrsineInput"
                                            checked={this.state.mostoviJavnePovrsineInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="Pješačke zone"
                                            child = {<BsFillSquareFill/>}
                                            style = {{
                                                color: "rgb(68,163,74)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="pjesackeJavnePovrsineInput"
                                            checked={this.state.pjesackeJavnePovrsineInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                    </div>
                                    
                                }
                            />
                            <SidebarContent
                                klasa = {this.state.activeitem.includes("javnaParkiralista") ? 'item-display': ''}
                                handleExpand={()=>this.handleExpandSpan("javnaParkiralista")}
                                ikonica = {this.state.activeitem.includes("javnaParkiralista") ? incompleteIcon: completeIcon}
                                imeNaselja={'Javna parkirališta'}
                                body = {
                                    <div>
                                        <Checkbox
                                            child = {<BsFillSquareFill/>}
                                            style = {{
                                                color: "rgb(152,78,163)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            nameCheckbox="Parkirališta s naplatom"
                                            name="parkiralistaNaplataJavnaInput"
                                            checked={this.state.parkiralistaNaplataJavnaInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            child = {<BsFillSquareFill/>}
                                            style = {{
                                                color: "rgb(193,148,200)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            nameCheckbox="Parkirališta"
                                            name="parkiralistaJavnaInput"
                                            checked={this.state.parkiralistaJavnaInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                    </div>
                                }
                            />
                            <SidebarContent
                                klasa = {this.state.activeitem.includes("javneZelenePovrsine") ? 'item-display': ''}
                                handleExpand={()=>this.handleExpandSpan("javneZelenePovrsine")}
                                ikonica = {this.state.activeitem.includes("javneZelenePovrsine") ? incompleteIcon: completeIcon}
                                imeNaselja={'Javne zelene površine'}
                                body = {
                                    <div>
                                        <Checkbox
                                            nameCheckbox="Sportski tereni"
                                            child = {<BsFillSquareFill/>}
                                            style = {{
                                                color: "rgb(204,204,204)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="sportskiTereniInput"
                                            checked={this.state.sportskiTereniInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="Dječja igrališta"
                                            child = {<BsFillSquareFill/>}
                                            style = {{
                                                color: "rgb(243,221,73)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="djecjaIgralistaInput"
                                            checked={this.state.djecjaIgralistaInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="Parkovi"
                                            name="parkoviInput"
                                            child = {<BsFillSquareFill/>}
                                            style = {{
                                                color: "rgb(61,167,90)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            checked={this.state.parkoviInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="Drvoredi, živice i travnjaci"
                                            name="zeleniloInput"
                                            child = {<BsFillSquareFill/>}
                                            style = {{
                                                color: "rgb(148,209,94)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            checked={this.state.zeleniloInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                    </div>
                                }
                            />
                            <SidebarContent
                                klasa = {this.state.activeitem.includes("gradjevine") ? 'item-display': ''}
                                handleExpand={()=>this.handleExpandSpan("gradjevine")}
                                ikonica = {this.state.activeitem.includes("gradjevine") ? incompleteIcon: completeIcon}
                                imeNaselja={'Građevine i uređaji javne namjene'}
                                body = {
                                    <div>
                                        <Checkbox
                                            nameCheckbox="Stajališta javnog prijevoza"
                                            child = {<BsFillSquareFill/>}
                                            style = {{
                                                color: "rgb(82,105,117)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="stajalistaPrijevozInput"
                                            checked={this.state.stajalistaPrijevozInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="Spomenici"
                                            child = {<BsFillCircleFill/>}
                                            style = {{
                                                color: "rgb(219,30,42)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="spomeniciInput"
                                            checked={this.state.spomeniciInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="Odlagališta otpada"
                                            child = {<BsFillSquareFill/>}
                                            style = {{
                                                color: "rgb(190,178,151)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="odlagalistaInput"
                                            checked={this.state.odlagalistaInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="Reciklažna dvorišta"
                                            style = {{
                                                color: "rgb(145,82,45)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            child = {<BsFillSquareFill/>}
                                            name="reciklaznaInput"
                                            checked={this.state.reciklaznaInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="Sajmišta i tržnice"
                                            name="trzniceInput"
                                            child = {<BsFillSquareFill/>}
                                            style = {{
                                                color: "rgb(251,154,153)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            checked={this.state.trzniceInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                         <Checkbox
                                            nameCheckbox="Građevine lokalnog značaja"
                                            name="gradjevineInput"
                                            child = {<BsFillSquareFill/>}
                                            style = {{
                                                color: "rgb(229,182,54)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            checked={this.state.gradjevineInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                    </div>
                                }
                            />
                             <SidebarContent
                                klasa = {this.state.activeitem.includes("grobljaKrematoriji") ? 'item-display': ''}
                                handleExpand={()=>this.handleExpandSpan("grobljaKrematoriji")}
                                ikonica = {this.state.activeitem.includes("grobljaKrematoriji") ? incompleteIcon: completeIcon}
                                imeNaselja={'Groblja i krematoriji'}
                                body = {
                                    <div>
                                        <Checkbox
                                            nameCheckbox="Groblja"
                                            child = {<BsFillSquareFill/>}
                                                        style = {{
                                                            color: "#525252",
                                                            marginRight: "5px",
                                                            padding: "1px"
                                                        }}
                                            name="grobljaInput"
                                            checked={this.state.grobljaInput}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                    </div>
                                }
                            />
                           
                            <SidebarContent
                                klasa = {this.state.activeitem.includes("rasvjeta") ? 'item-display': ''}
                                handleExpand={()=>this.handleExpandSpan("rasvjeta")}
                                ikonica = {this.state.activeitem.includes("rasvjeta") ? incompleteIcon: completeIcon}
                                imeNaselja={'Rasvjeta'}
                                body = {
                                    <div>
                                        <Checkbox
                                            nameCheckbox="BARACUDA 4/5/6 MEW, 4 AT2"
                                            child = {<BsFillCircleFill/>}
                                            style = {{
                                                color: "#1f78b4",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="rasvjeta1Input"
                                            checked={this.state.rasvjeta1Input}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="BARACUDA 5/6/10/18 MEW, 6/10 AT2"
                                            child = {<BsFillCircleFill/>}
                                            style = {{
                                                color: "#e84c54",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="rasvjeta2Input"
                                            checked={this.state.rasvjeta2Input}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="BARACUDA 4/5 MEW"
                                            child = {<BsFillCircleFill/>}
                                            style = {{
                                                color: "#18af18",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="rasvjeta3Input"
                                            checked={this.state.rasvjeta3Input}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="VELUM 24P"
                                            child = {<BsFillCircleFill/>}
                                            style = {{
                                                color: "#d53fc9",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="rasvjeta4Input"
                                            checked={this.state.rasvjeta4Input}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="BARACUDA 5/8/12 MEW, 6/10 AT2"
                                            child = {<BsFillCircleFill/>}
                                            style = {{
                                                color: "rgb(32, 32, 32)",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="rasvjeta5Input"
                                            checked={this.state.rasvjeta5Input}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                         <Checkbox
                                            nameCheckbox="BARACUDA 16 AT2"
                                            child = {<BsFillCircleFill/>}
                                            style = {{
                                                color: "#32c7c5",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="rasvjeta6Input"
                                            checked={this.state.rasvjeta6Input}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                        <Checkbox
                                            nameCheckbox="BARACUDA 5 MEW"
                                            child = {<BsFillCircleFill/>}
                                            style = {{
                                                color: "#f5ee30",
                                                marginRight: "5px",
                                                padding: "1px"
                                            }}
                                            name="rasvjeta7Input"
                                            checked={this.state.rasvjeta7Input}
                                            Change={this.toggleChange.bind(this)}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
                
            </div> 
            }
        </div>
    )
}}