import {obj_ceste} from '../Komponente/data/Nerazvrstaneceste';

import {obj_eki} from '../Komponente/data/Javnepovrsinebezprometa';

export const columnsnerazceste = [
    {   name: "Oznaka",
        selector: "OZNAKA",
        sortable: true
    },
    {   name: "Naziv ulice",
        selector: "UL_IME",
        sortable: true
    },
    {   name: "Naziv naselja",
        selector: "NA_IME",
        sortable: true,
    },
    {   name: "Naziv županije",
        selector: "ZU_IME",
        sortable: true,
    },
    {   name: "Zastor",
        selector: "ZASTOR",
        sortable: true,
    },
    {   name: "Duljina (m)",
        selector: "DULJINA",
        sortable: true,
    },
    {   name: "Kat. čestice",
        selector: "KCBR",
        sortable: true,
    },
    {   name: "Vlasništvo",
        selector: "VLASNISTVO",
        sortable: true,
    },

];

export const nerazcestepodaci = obj_ceste;

export const columnseki = [
    {   name: "Vrsta",
        selector: "Vrsta",
        sortable: true
    },
    {   name: "Oznaka",
        selector: "Oznaka",
        sortable: true
    },
    {   name: "Naziv",
        selector: "Naziv",
        sortable: true,
    },
    {   name: "Naselje",
        selector: "Naselje",
        sortable: true,
    },
    {   name: "Upravitelj",
        selector: "Upravitelj",
        sortable: true,
    },
    {   name: "Kat. općina",
        selector: "Kat opcina",
        sortable: true,
    },
    {   name: "Kat. čestica",
        selector: "kcbr",
        sortable: true,
    },
    {   name: "Broj zkc",
        selector: "zkc",
        sortable: true,
    },
    {   name: "Vlasništvo",
        selector: "Vlasnistvo",
        sortable: true,
    },
];

export const ekipodaci = obj_eki;