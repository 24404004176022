import React, { Component } from 'react';
import LogoPage from './LogoPage';
import PropTypes from 'prop-types';
import * as user from '../user.json';
import './Login.css';

async function loginUser(credentials) {
    return fetch('https://ekipag.li-st.net/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
};
   
export default class Login extends Component {
    _isMounted = false;
    state = {
        valueusername: '',
        valuepassword: '',
        flip: '',
        username: '',
        password: '',
        messageuser: '',
    };

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ valueusername: ''});
            this.setState({ valuepassword: ''});
            this.setState({ flip: ''});
            this.setState({ username: ''});
            this.setState({ password: ''});
            this.setState({ messageuser: ''});
        };
    };
    
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = (state,callback)=>{
            return;
        };
    };
   
    CheckUserName = (e) => {
        let inputuser = e.target.value;
        this.setState({valueusername: inputuser}); 
        this.setState({username: inputuser});
        e.target.classList.add('has-content');
    };

    CheckPassword = (e) => {
        let inputpassword = e.target.value;
        e.target.classList.add('has-content');
        this.setState({valuepassword: inputpassword}); 
        this.setState({password: inputpassword}); 
    };

    handleSubmit = async e => {
        e.preventDefault();
        let token;

        let userLogin =  user.default.user.find( user => {
            if(user.username === this.state.username && user.password === this.state.password) {
                return user;
            } else return false;
        });  

        if (userLogin) {
            let username = this.state.username;
            let password = this.state.password;
            this.setState({valueusername: username}); 
            this.setState({valuepassword: password}); 
            this.setState({messageuser: ''});
            token = await loginUser({username, password});
            this.props.setToken(token);
        } else {
            this.setState({messageuser: '*Račun ili lozinka nisu točni!'});
            this.setState({valueusername: ''}); 
            this.setState({valuepassword: ''});
        };       
    };

    onClickLogin = () => {
        this.setState({flip: 'back'});
        document.getElementsByClassName('logo-login-page')[0].classList.add('transition-logo');
    };

    render () {
        return (
            <div className="login-page">
                <LogoPage onClickLogin={this.onClickLogin} className="logo-login-page"/>

                <form onSubmit={this.handleSubmit} className={`login-forma ${this.state.flip === "back" ? 'fade-in-translate' : ''}`}>
                    <div className="div-login first">
                        <input id="input-one" value={this.state.valueusername} type="text" onChange={e => this.CheckUserName(e)}/>
                        <label className="label-first" >Korisničko ime</label>
                        <span>{this.state.messageuser ? this.state.messageuser : ''}</span>
                    </div>
                    <div className="div-login">
                        <input id="input-two" type="password" autoComplete="of" value={this.state.valuepassword} onChange={e => this.CheckPassword(e)}/>
                        <label>Lozinka</label>
                        <span>{this.state.messageuser ? this.state.messageuser : ''}</span>
                    </div>
                <div>
                    <button type="submit" className="login-button">Prijava</button>
                </div>
                </form>
            </div>
        )
    };
;}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};

